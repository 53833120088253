import Vue from 'vue'
import VueRouter from 'vue-router'

import LiveCameraView from '@/components/LiveCameraView'
import Settings from '@/components/Settings'
import DeviceSettings from '@/components/Devices/DeviceSettings'
import AddCamera from '@/components/AddCamera'
import Logout from '@/components/Logout'
import LoggedIntoOtherApp from '@/components/LoggedIntoOtherApp'
import EventSearch from '@/components/Events/EventSearch'
import FootageView from '@/components/FootageView'
import Cameras from '@/components/Cameras/Cameras'
import Recording from "@/components/Cameras/Recording";
import CameraInfo from "@/components/Cameras/CameraInfo";
import Detections from "@/components/Cameras/Detections";
import Networks from "@/components/Cameras/Networks";
import VideoSound from "@/components/Cameras/VideoSound";
import DetectionAreas from "@/components/Cameras/DetectionAreas";
import ScheduleNotifications from "@/components/Cameras/ScheduleNotifications";
import Zones from '@/components/Zones/Zones'
import EditZone from '@/components/Zones/EditZone'
import Users from '@/components/Users/Users'
import SingleUser from '@/components/Users/SingleUser'
import UserRights from '@/components/Users/UserRights'
import UserDetails from '@/components/Users/UserDetails'
import UserCameras from "@/components/Users/UserCameras"
import Logs from "@/components/Logs/Logs"
import DeciveInfo from "@/components/Devices/DeviceInfo"
import DeviceChannels from '@/components/Devices/DeviceChannels'
import DeviceNetworks from '@/components/Devices/DeviceNetworks' 
import AddCameraWithOldQRSetup from '@/components/AddCameraWithOldQRSetup'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};


Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Settings,
      props: true,
      meta: {
        auth: true,
      },
    },
    {
      path: '/live/:cameraId?',
      name: 'Live',
      component: LiveCameraView,
      props: true,
      meta: {
        auth: true,
      },
    },
    {
      path: '/footage/:cameraId?',
      name: 'Footage',
      component: FootageView,
      props: true,
      meta: {
        auth: true,
      },
    },
    {
      path: '/settings/cameras',
      name: 'Cameras',
      component: Cameras,
      props: true,
      meta: {
        auth: true,
      }
    },
    {
      path: '/settings/logs',
      name: 'Logs',
      component: Logs,
      props: true,
      meta: {
        auth: true,
      }
    },
    {
      path: '/settings/cameras/:cameraId?',
      name: 'Settings',
      component: Settings,
      props: true,
      meta: {
        auth: true,
      },
      children: [
        { path: '', name: 'CameraInfo', component: CameraInfo },
        { path: 'info', name: 'CameraInfo', component: CameraInfo },
        { path: 'recording', name: 'Recording', component: Recording },
        { path: 'detections', name: 'Detections', component: Detections },
        { path: 'networks', name: 'Networks', component: Networks },
        { path: 'video', name: 'VideoSound', component: VideoSound },
        { path: 'detectionareas', name: 'DetectionAreas', component: DetectionAreas },
        { path: 'schedule', name: 'ScheduleNotifications', component: ScheduleNotifications },
      ]
    },
    {
      path: '/settings/devices/:deviceId?',
      name: 'DeviceSettings',
      component: DeviceSettings,
      props: true,
      meta: {
        auth: true,
      },
      children: [
        { path: '', name: 'DeviceInfo', component: DeciveInfo },
        { path: 'info', name: 'DeviceInfo', component: DeciveInfo },
        { path: 'channels', name: 'DeviceChannels', component: DeviceChannels },
        { path: 'networks', name: 'DeviceNetworks', component: DeviceNetworks },
      ]
    },
    {
      path: '/cameras/add/:zoneId?',
      name: 'AddCamera',
      component: AddCamera,
      props: true,
      meta: {
        auth: true,
      },
    },
    {
      path: '/events',
      name: 'EventSearch',
      component: EventSearch,
      props: true,
      meta: {
        auth: true,
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
      meta: {
        auth: false,
        title: 'Logout',
      },
    },
    {
      path: '/firstlogout',
      name: 'Logged in at other app',
      component: LoggedIntoOtherApp,
      meta: {
        auth: false,
        title: 'Logged in at other app',
      },
    },
    {
      path: '/settings/zones',
      name: 'Zones',
      component: Zones,
      props: true,
      meta: {
        auth: true,
      }
    },
    {
      path: '/settings/zones/:zoneId?',
      name: 'EditZone',
      component: EditZone,
      props: true,
      meta: {
        auth: true,
      }
    },
    {
      path: '/settings/users',
      name: 'Users',
      component: Users,
      props: true,
      meta: {
        auth: true,
      }
    },
    {
      path: '/settings/users/:userId',
      name: 'SingleUser',
      component: SingleUser,
      props: true,
      meta: {
        auth: true,
      },
      children: [
        { path: 'details', name: 'UserDetails', component: UserDetails },
        { path: 'rights', name: 'UserRights', component: UserRights },
        { path: 'cameras', name: 'UserCameras', component: UserCameras}
      ]
    },
    {
      path: '*',
      redirect: '/dashboard',
    },
    {
      path: '/cameras/addoldqr', //temporary route to allow testing a new page
      name: 'AddCameraWithOldQRSetup',
      component: AddCameraWithOldQRSetup,
      props: true,
      meta: {
        auth: true,
      },
    },
  ],
})

export default router
